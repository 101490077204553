<template>
  <v-card>
    <v-card-title> Cookie Policy </v-card-title>
    <v-card-text> Last Updated: 28th Sep 2022 </v-card-text>
    <v-card-text>
      This Cookie Policy describes what cookies are used on the ‘Bulk Uploader’, ‘{{ siteUrl }}’ (“Site”), which is
      owned and managed by KPM Music. This Policy also describes why cookies are used and how you can control their use.

      <h4>What is a cookie?</h4>

      A cookie is a simple text file, containing small amounts of information, that a website asks your browser to store
      on your device to remember information about you.

      <h3>Cookies used on this Site</h3>

      <h4>Strictly Necessary Cookies</h4>

      These cookies are necessary for the website to function and cannot be switched off in our systems. They are
      usually only set in response to actions made by you which amount to a request for services. You can set your
      browser to block or alert you about these cookies, but certain Site features or functions may not wot work if
      Strictly Necessary Cookies are blocked.

      <h4>Optional Cookies</h4>

      This site does not use any Optional Cookies, such as Performance, Functional or Targeting cookies. Note it is not
      possible to opt out of Strictly Necessary Cookies.
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      siteUrl: process.env.VUE_APP_APP_ENDPOINT,
    }
  },
}
</script>
